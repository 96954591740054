<template>
    <div :class="[$store.state.sidedraweropen ? 'w-60 position-fixed' : 'all-width position-fixed']" v-if="pagecount > 1"
        style="bottom:0; overflow: hidden;background:white;z-index:11">
        <v-divider></v-divider>
        <v-row justify="center" class="d-flex pt-3 align-center justify-space-between">
            <v-col :cols="$store.state.sidedraweropen ? 4 : 2" class="pa-0 all-width">
                <v-container>
                    <v-pagination v-model="page" class="font-small" :length="pagecount" @input="emitPaginatedData"></v-pagination>
                </v-container>
            </v-col>
            <v-col cols="3">
                <div class="d-flex align-center justify-start">
                    <p class="ma-0 pa-0 px-2">Go to this page</p>
                    <lb-number min="1" class="mr-1" label="" hidedetails :max="pagecount" @input="handlePageInput"
                        @change="emitPaginatedData" v-model="page" :style="`max-width:65px !important`" />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    data() {
        return {
            page: 1,
            pageInput: null,
        };
    },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        itemsperpage: {
            type: Number,
            default: 20,
        },
    },
    watch: {
        page() {
            this.emitPaginatedData();
        },
        list() {
            this.emitPaginatedData();
        }
    },
    methods: {
        emitPaginatedData() {
            const start = (this.page - 1) * this.itemsperpage;
            const end = this.page * this.itemsperpage;
            const paginatedData = this.list.slice(start, end);
            this.$emit('paginated-data', paginatedData);  
        },
        handlePageInput(value) {
            const pageValue = parseInt(value, 10);
            if (pageValue > this.pagecount) {
                this.page = this.pagecount;
            } else if (pageValue < 1) {
                this.page = 1;
            } else {
                this.page = pageValue;
            }
            this.emitPaginatedData();
        },
    },
    computed: {
        pagecount() {
            return Math.ceil(this.list.length / this.itemsperpage);
        },
    },
    mounted() {
        this.emitPaginatedData(); // Emit the first page of data when the component is mounted
    },
};
</script>
